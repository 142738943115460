"use server"

import Lenis from '@studio-freight/lenis'
import { useEffect } from 'react';
import './App.css';
import Hero from './components/Hero';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Footer from './components/Footer';

function App() {
 
  useEffect( () => {
    const lenis = new Lenis()
    
    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    
    requestAnimationFrame(raf)
}, [])

  return (
   <div className="bg-gray-300">
    
    <Hero />
    <Section2 />
    <Section3 />
    <Footer />
   </div>
  );
}

export default App;

import { motion } from "framer-motion"

function Section2() {

return (
    <div className="w-full h-screen font-poppins " id="about">
       <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
        <motion.img
        initial={{ opacity: 0, x: "100%"}}
        whileInView={{opacity: 1, x:0 }}
        transition={{ duration: 1.5}}

          alt=""
          src={process.env.PUBLIC_URL + '/Image3.jpg'}
          className="absolute inset-0 h-full w-full object-cover xl:w-[85%] xl:h-[30rem] shadow-lg shadow-black"
        />
      </div>

      <motion.div 
      initial={{ opacity: 0, x: "-100%"  }}
      whileInView={{opacity: 1, x:0 }}
        transition={{ duration: 1.5}}
      class="lg:py-24">
        <h2 class="text-5xl text-center mr-8 font-bold sm:text-4xl xl:text-7xl">ABOUT ME</h2>

        <p class="mt-4 text-gray-600">
        I am a passionate Computer Engineering student at Vaal University of Technology, deeply fascinated by the world of web development. With a keen interest in design and a knack for problem-solving, I thrive on the creative process of building and designing websites. From crafting visually appealing layouts to implementing robust functionality, I am dedicated to honing my skills and creating meaningful digital experiences. I am excited about the endless possibilities that technology offers and look forward to making a positive impact in the field of web development.
        </p>
      </motion.div>
    </div>
  </div>
    </div>
);
}
export default Section2
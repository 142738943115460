import Navbar from "./Navbar"
import { motion } from "framer-motion"

function Hero() {
return (
    <div className="w-full h-screen bg-gray-300">
     <Navbar />
    
    <div className="flex w-full items-center justify-center place-content-center">
        <motion.div
            initial={{ opacity: 0, y: "100%"  }}
            animate={{opacity: 1, y:0 }}
            transition={{ duration: 1.2}}

        className="head text-[65px] items-center justify-center
        h-1/3 
        p-4 mt-10 font-normal font-anton text-left ml-4 leading-[1] 
        text-balance lg:text-[5.7rem] lg:text-center lg:mt-12 lg:w-[75%] ">
        HEY, IM THOKOZANI MAVUSO AND IM A WEB DEVELOPER AND A COMPUTER ENGINEERING STUDENT
        </motion.div>
 </div>
 </div>
)
}
export default Hero
"use client"

import { motion } from "framer-motion"


function Section3() {
return (
 
    <div className="w-full h-screen" id="activities">
        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 lg:flex-col-reverse">
                
            <motion.div 
                
                initial={{ opacity: 0, x: 90  }}
                whileInView={{opacity: 1, x:0 }}
                transition={{ duration: 1.5}}

                class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                    <img
                        alt=""
                        src={process.env.PUBLIC_URL + "/chess.jpg"}
                        class="absolute inset-0 h-full w-full object-cover  xl:w-[90%] xl:h-[30rem] shadow-xl shadow-black"
                    />
                </motion.div>
                
                
                
                <motion.div 
                
                initial={{ opacity: 0, x: -90  }}
                whileInView={{opacity: 1, x:0 }}
                transition={{ duration: 1.5}}

                class="lg:py-24">
                    <h2 class="text-5xl text-center font-bold sm:text-4xl xl:text-7xl">ACTIVITIES</h2>

                    <p class="mt-4 text-gray-600 text">
                        Amongst many of the things I like to do, chess is my passion. I have been playing competitively for the last 3 years and have come out in the top 2 in 98% of the tournaments I played in. I also Had the opportunity to participate in the Le Chevelier Rapid chess tournament with many of the best chess players in South Africa one of which was the esteemed International Master Daniel Cawdery (Fide Peak: 2419)
                    </p>
                </motion.div>

            </div>
        </div>
    </div>
)
}
export default Section3
import { motion } from "framer-motion"
import { BrowserRouter } from "react-router-dom"
import { HashLink as Link } from "react-router-hash-link"


function Navbar() {
return (
   <BrowserRouter> 
    <motion.div
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        transition={{duration: 1,  delay: 1.3}}
        className=" p-4 flex items-center justify-between"
    >
        <div className="Logo text-[70px] font-normal text-center font-lobster">
             tm.
        </div>

        <div>
            <ul className="flex flex-col font-poppins mr-4 items-left leading-snug">
                <Link to='#home' className="text-md font-normal" smooth>Home</Link>
                <Link to='#about' className="text-md font-normal" smooth>About</Link>
                <Link to='#activities' className="text-md font-normal" smooth>Activities</Link>
                <Link to='#contact' className="text-md font-normal" smooth>Contact</Link>
            </ul>
        </div>

    </motion.div>
    </BrowserRouter>
    
)
}
export default Navbar